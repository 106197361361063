/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import HelpIcon from '@material-ui/icons/HelpOutline';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import Label from 'src/components/Label';

export default [
  {
    // subheader: 'Support',
    items: [
      {
        title: 'Find meeting',
        href: '/meetings',
        icon: CalendarTodayIcon
      },
      {
        title: 'Find coach',
        href: '/coaches',
        icon: PeopleIcon
      },
    ]
  },
  {
    subheader: 'My Support Groups',
    items: [
      {
        title: 'Feed',
        href: '/social-feed',
        icon: ListAltIcon,
        label: () => <Label color={colors.green[500]}>New</Label>
      },
      {
        title: 'Profile',
        href: '/profile',
        icon: PersonIcon,
        items: [
          {
            title: 'Timeline',
            href: '/profile/1/timeline'
          },
          {
            title: 'Connections',
            href: '/profile/1/connections'
          },
          {
            title: 'Projects',
            href: '/profile/1/projects'
          },
          {
            title: 'Reviews',
            href: '/profile/1/reviews'
          }
        ]
      },
      {
        title: 'Chat',
        href: '/chat',
        icon: ChatIcon,
        label: () => (
          <Label
            color={colors.red[500]}
            shape="rounded"
          >
            4
          </Label>
        )
      }
    ]
  },
  {
    subheader: 'Help',
    items: [
      {
        title: 'Feedback',
        href: '/errors',
        icon: HelpIcon
      },
      {
        title: 'Settings',
        href: '/settings',
        icon: SettingsIcon,
        items: [
          {
            title: 'General',
            href: '/settings/general'
          },
          {
            title: 'Subscription',
            href: '/settings/subscription'
          },
          {
            title: 'Notifications',
            href: '/settings/notifications'
          },
          {
            title: 'Security',
            href: '/settings/security'
          }
        ]
      }
    ]
  }
];

/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
} from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import track from 'src/utils/analytics';

export const TOP_BAR_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  toolbar: {
    minHeight: TOP_BAR_HEIGHT,
  },
  flexGrow: {
    flexGrow: 1
  },
  logo: {
    marginLeft: 4,
    marginTop: -4,
    fontSize: '1.8em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.6em'
    },
    fontFamily: 'Times, Serif', 
    color: 'white' // TODO: This feels like an anti-pattern. Also, colors.white doesn't work.
  },
  avatar: {
    // marginRight: theme.spacing(2),
    cursor: 'pointer',
    width: 30,
    height: 30
  },
  menuButton: {
    padding: 6,
    marginRight: theme.spacing(1)
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  navbarbutton: {
    textTransform: 'none',
    paddingRight: 0,
    marginLeft: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8em',
      marginLeft: 4,
    }
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}));

const popularSearches = [
  'Spanish',
];

export function TopBar({
  onOpenNavBarMobile,
  className,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const searchRef = useRef(null);
  // const dispatch = useDispatch();
  // const notificationsRef = useRef(null);
  // const [openSearchPopover, setOpenSearchPopover] = useState(false);
  // const [searchValue, setSearchValue] = useState('');
  // const [notifications, setNotifications] = useState([]);
  // const [openNotifications, setOpenNotifications] = useState(false);
  // const [openChatBar, setOpenChatBar] = useState(false);
  const session = useSelector((state) => state.session);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const onOpenNavBar = () => {
    onOpenNavBarMobile();
    track.event('navbar_click');
  }

  // const handleLogout = () => {
  //   history.push('/auth/login');
  //   // dispatch(logout());
  // };

  // const handleSearchChange = (event) => {
  //   setSearchValue(event.target.value);

  //   if (event.target.value) {
  //     if (!openSearchPopover) {
  //       setOpenSearchPopover(true);
  //     }
  //   } else {
  //     setOpenSearchPopover(false);
  //   }
  // };

  // const handleSearchPopverClose = () => {
  //   setOpenSearchPopover(false);
  // };

  // useEffect(() => {
  //   let mounted = true;

  //   const fetchNotifications = () => {
  //     axios.get('/api/account/notifications').then((response) => {
  //       if (mounted) {
  //         setNotifications(response.data.notifications);
  //       }
  //     });
  //   };

  //   fetchNotifications();

  //   return () => {
  //     mounted = false;
  //   };
  // }, []);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar className={classes.toolbar}>
        {/* <img
            alt="Logo"
            src="/images/logos/rlogo_small.png"
        /> */}
        <RouterLink to="/">
          <h1 className={classes.logo}>Recove</h1>
        </RouterLink>
        <div className={classes.flexGrow} />

        { /* Search Bar */ }
        {/* <Hidden smDown>
          <div
            className={classes.search}
            ref={searchRef}
          >
            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              placeholder="Search people &amp; places"
              value={searchValue}
            />
          </div>
          <Popper
            anchorEl={searchRef.current}
            className={classes.searchPopper}
            open={openSearchPopover}
            transition
          >
            <ClickAwayListener onClickAway={handleSearchPopverClose}>
              <Paper
                className={classes.searchPopperContent}
                elevation={3}
              >
                <List>
                  {popularSearches.map((search) => (
                    <ListItem
                      button
                      key={search}
                      onClick={handleSearchPopverClose}
                    >
                      <ListItemIcon>
                        <SearchIcon />
                      </ListItemIcon>
                      <ListItemText primary={search} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </Hidden> */}

        <> { /* <Hidden xsDown> */ }
          {isLoggedIn
            ? (
              // Avatar
              <div>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  onClick={onOpenNavBar}
                >
                  <Badge
                    badgeContent={7} // {notifications.length}
                    color="secondary"
                    variant="dot" // Replaces number with dot.
                  >
                    <Avatar
                      alt="Person"
                      className={classes.avatar}
                      src={session.user.avatar}
                    />
                  </Badge>
                </IconButton>
                <Button
                  // className={classes.logoutButton}
                  // color="inherit"
                  // onClick={handleLogout}
                  className={classes.navbarbutton}
                  color="inherit"
                  onClick={onOpenNavBar}
                >
                  {`${session.user.first_name} ${session.user.last_name}`}
                  <ExpandIcon />
                </Button>
              </div>
            )
            : (
              // <Button
              //   className={classes.navbarbutton}
              //   color="inherit"
              //   onClick={onOpenNavBar}
              // >
              //   Find Support
              //   {/* <ExpandIcon /> */}
              // </Button>
              <>
                <Button
                  className={classes.navbarbutton}
                  color="inherit"
                  onClick={()=>{track.event('topbar_meetings')}}
                  component="a"
                  href="/meetings"
                >
                  Meetings
                </Button>
                <Button
                  className={classes.navbarbutton}
                  color="inherit"
                  onClick={()=>{track.event('topbar_coaches')}}
                  component="a"
                  href="/coaches"
                >
                  Coaches
                </Button>
                <Button
                  className={classes.navbarbutton}
                  color="inherit"
                  onClick={()=>{track.event('topbar_medication')}}
                  component="a"
                  href="/medication-assisted"
                >
                  Medication
                </Button>
              </>
            )}
        </> {/* </Hidden> */}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import track from 'src/utils/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    paddingTop: 64,
    paddingBottom: 64
  },
  product: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 600,
      marginBottom: 24,
      margin: 'auto' // center
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 300, // Keep "Coaches and sponsors" on 1 line
      maxWidth: 380, // Keep "Medication-assisted therapy" on 2 lines
      '&:hover': {
        transform: 'scale(1.1)'
      }
    },
    padding: theme.spacing(5, 3),
    cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
  },
  productIcon: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  // productTitle: {
  // },
  productSubtitle: {
    height: 28
  },
  productDescription: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 36
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 60
    }
  },
  recommendedProduct: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  chooseButton: {
    backgroundColor: theme.palette.common.white
  }
}));

function Overview() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Recove"
    >
      <Container maxWidth="sm">
        <Typography
          align="center"
          variant="h1"
          color="textPrimary"
        >
          Find the support you need.
        </Typography>
        <Box mt={3}>
          <Typography
            align="center"
            variant="subtitle1"
            color="textSecondary"
          >
            And change your relationship with alcohol.
          </Typography>
        </Box>
      </Container>
      <Box mt="80px">
        <Container maxWidth="lg">
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper
                className={clsx(classes.product, classes.recommendedProduct)}
                elevation={1}
              >
                <img
                  alt="AA"
                  className={classes.productIcon}
                  src="/images/products/group.svg"
                />
                <Typography
                  component="h3"
                  gutterBottom
                  variant="overline"
                  color="inherit"
                >
                  Group Support
                </Typography>
                <div className={classes.productTitle}>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                    color="inherit"
                  >
                    Online AA meetings
                  </Typography>
                  {/* <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                    color="inherit"
                  >
                    /month
                  </Typography> */}
                </div>
                <div className={classes.productSubtitle}>
                  <Typography
                    variant="overline"
                    color="inherit"
                  >
                    Personalized to you
                  </Typography>
                </div>
                <Box my={2}>
                  <Divider />
                </Box>
                <div className={classes.productDescription}>
                  <Typography
                    variant="body1"
                    color="inherit"
                  >
                    Find online AA meetings that fit your needs: people like you, speaking your language, any time of day.
                  </Typography>
                </div>
                <Box my={2}>
                  <Divider />
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.chooseButton}
                  onClick={()=>{track.event('welcome_meetings')}}
                  component="a"
                  href="/meetings"
                >
                  Search meetings
                </Button>
              </Paper>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper
                className={clsx(classes.product, classes.recommendedProduct)}
                elevation={1}
              >
                <img
                  alt="MAT"
                  className={classes.productIcon}
                  src="/images/products/pill.svg" // pill_bottle_heart_small.png"
                />
                <Typography
                  component="h3"
                  gutterBottom
                  variant="overline"
                  color="inherit"
                >
                  Medical support
                </Typography>
                <div className={classes.productTitle}>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                    color="inherit"
                  >
                    Medication-Assisted Treatment
                  </Typography>
                </div>
                {/* <Typography
                  variant="overline"
                  color="inherit"
                >
                  Prescriptions
                </Typography> */}
                <Box my={2}>
                  <Divider />
                </Box>
                <div className={classes.productDescription}>
                  <Typography
                    variant="body1"
                    color="inherit"
                  >
                    Speak with a clinician about medications that are proven to help reduce drinking.
                    <br />
                    {/* If appropriate, the doctor can discreetly prescribe a medication to be used in concert with the rest of your alcohol treatment. */}
                  </Typography>
                </div>
                <Box my={2}>
                  <Divider />
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.chooseButton}
                  onClick={()=>{track.event('welcome_medication')}}
                  component="a"
                  href="/medication-assisted"
                >
                  Learn more
                </Button>
              </Paper>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper
                className={clsx(classes.product, classes.recommendedProduct)}
                elevation={1}
              >
                <img
                  alt="Product"
                  className={classes.productIcon}
                  src="/images/products/coach.svg"
                />
                <Typography
                  component="h3"
                  gutterBottom
                  variant="overline"
                  color="inherit"
                >
                  Peer support
                </Typography>
                <div className={classes.productTitle}>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                    color="inherit"
                  >
                    Coaches and sponsors
                  </Typography>
                </div>
                <div className={classes.productSubtitle}>
                  <Typography
                    variant="overline"
                    color="inherit"
                  >
                    Virtual sessions
                  </Typography>
                </div>
                <Box my={2}>
                  <Divider />
                </Box>
                <div className={classes.productDescription}>
                  <Typography
                    variant="body1"
                    color="inherit"
                  >
                    Virtual sessions with peer coaches to help you achieve your alcohol goals.
                  </Typography>
                </div>
                <Box my={2}>
                  <Divider />
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.chooseButton}
                  onClick={()=>{track.event('welcome_coaches')}}
                  component="a"
                  href="/coaches"
                >
                  Find peer coaching
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
}

export default Overview;

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import Welcome from './views/Welcome';

export default [
  // The difference between this section and the section starting with route: '*'
  // is that the latter loads Dashboard layout and therefore has e.g. a TopBar.
  // Uncomment it if you want a page without the TopBar:
  // {
  //   path: '/',
  //   exact: true,
  //   component: Welcome // () => <Redirect to="/welcome" />
  // },
  //
  // Auth stuff:
  // {
  //   path: '/auth',
  //   component: AuthLayout,
  //   routes: [
  //     {
  //       path: '/auth/login',
  //       exact: true,
  //       component: lazy(() => import('src/views/Login'))
  //     },
  //     {
  //       path: '/auth/register',
  //       exact: true,
  //       component: lazy(() => import('src/views/Register'))
  //     },
  //     {
  //       component: () => <Redirect to="/errors/error-404" />
  //     }
  //   ]
  // },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: Welcome // () => <Redirect to="/welcome" />
      },    
      // {
      //   path: '/calendar',
      //   exact: true,
      //   component: lazy(() => import('src/views/Calendar'))
      // },
      // {
      //   path: '/chat',
      //   exact: true,
      //   component: lazy(() => import('src/views/Chat'))
      // },
      // {
      //   path: '/chat/:id',
      //   exact: true,
      //   component: lazy(() => import('src/views/Chat'))
      // },
      {
        path: '/coaches',
        exact: true,
        component: lazy(() => import('src/views/Coaches'))
      },
      {
        path: '/medication-assisted',
        exact: true,
        component: lazy(() => import('src/views/Medication'))
      },
      {
        path: '/meetings',
        exact: true,
        component: lazy(() => import('src/views/MeetingList'))
      },
      // {
      //   path: '/profile/:id',
      //   exact: true,
      //   component: lazy(() => import('src/views/Profile'))
      // },
      // {
      //   path: '/profile/:id/:tab',
      //   exact: true,
      //   component: lazy(() => import('src/views/Profile'))
      // },
      // {
      //   path: '/settings',
      //   exact: true,
      //   component: lazy(() => import('src/views/Settings'))
      // },
      // {
      //   path: '/settings/:tab',
      //   exact: true,
      //   component: lazy(() => import('src/views/Settings'))
      // },
      // {
      //   path: '/social-feed',
      //   exact: true,
      //   component: lazy(() => import('src/views/SocialFeed'))
      // },
      {
        path: '/welcome',
        exact: true,
        component: Welcome
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import HelpIcon from '@material-ui/icons/HelpOutline';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import Label from 'src/components/Label';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';

export default [
  {
    // subheader: 'Support',
    items: [
      {
        title: 'Find online meeting',
        href: '/meetings',
        icon: CalendarTodayIcon
      },
      {
        title: 'Find coach or sponsor',
        href: '/coaches',
        icon: PeopleIcon
      },
      {
        title: 'Medication-assisted treatment',
        href: '/medication-assisted',
        icon: LocalHospitalOutlinedIcon
      },
      // {
      //   title: 'Facilities',
      //   href: '/projects',
      //   icon: HomeWorkIcon,
      //   items: [
      //     {
      //       title: 'Browse',
      //       href: '/projects'
      //     },
      //     {
      //       title: 'Create',
      //       href: '/projects/create'
      //     },
      //     {
      //       title: 'Overview',
      //       href: '/projects/1/overview'
      //     },
      //     {
      //       title: 'Files',
      //       href: '/projects/1/files'
      //     },
      //     {
      //       title: 'Activity',
      //       href: '/projects/1/activity'
      //     },
      //     {
      //       title: 'Subscribers',
      //       href: '/projects/1/subscribers'
      //     }
      //   ]
      // }
    ]
  },
];

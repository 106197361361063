import { colors } from '@material-ui/core';

const white = '#FFFFFF';

export default {
  primary: {
    contrastText: white,
    dark: '#360063', // colors.purple[900],
    main: '#4E008E', // colors.purple[500],
    light: '#7133a4', // colors.purple[100]
  },
  // primary: {
  //   contrastText: white,
  //   dark: '#5d8285', // '#6c969a', // colors.lightBlue[900],
  //   main: '#86BABF', // '#9BD7DD', // colors.lightBlue.A700,
  //   light: '#9ec7cb', // '#afdfe3', // colors.lightBlue.A400
  // },
  secondary: {
    contrastText: white,
    dark: colors.lightBlue[900],
    main: colors.lightBlue.A700,
    light: colors.lightBlue.A400
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
};
